
/**
 * The main layout
 * @category [Layouts]
 * @nuxt-listeners [
 *   'change-global-loading', 'change-global-scrolling', 'change-global-overlay'
 * ]
 */
import { GET_META_DATA } from '@/api/apolloCommonQueries';
import { GET_ABOUT_US_HEADER } from '~/api/apolloAboutUsQueries';
import headerMenuOverlayUtility from '~/mixins/headerMenuOverlayUtility';
import respUtility from '@/mixins/respUtility';

export default {
  name: 'simpleLayout',
  mixins: [
    headerMenuOverlayUtility,
    respUtility,
  ],
  components: {
    TheLoading: () => import('~/components/templates/TheLoading.vue'),
  },
  data() {
    return {
      redirect: null,
      overlay: false,
      hasOverlayWithDelay: false,
      loadingPopup: false,
      footerData: null,
      headerData: null,
      metaData: null,
    };
  },
  head() {
    return {
      title: this.headData?.title || '1StopBedrooms',
      script: [
        {
          // eslint-disable-next-line max-len,vue/max-len
          src: `https://www.googletagmanager.com/gtag/js?id=${this.$config.googleConversion.googleTagManagerGtagAwConversionId}`,
          skip: !this.$config.googleConversion.googleTagManagerGTagGconversionEnable || this.$isSpider,
          async: true,
          body: true,
        },
        {
          type: 'text/javascript',
          innerHTML: `window.dataLayer = window.dataLayer || [];
            if (typeof gtag !== 'function'){
              function gtag(){dataLayer.push(arguments);}
            }
            gtag('js', new Date());
            gtag('config', '${this.$config.googleConversion.googleTagManagerGtagAwConversionId}');`,
          skip: !this.$config.googleConversion.googleTagManagerGTagGconversionEnable || this.$isSpider,
          body: true,
        },
        {
          type: 'text/javascript',
          innerHTML: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${this.$config.googleAnalytics.googleAnalyticsFourAccount}',{'send_page_view': false});`,
          skip: !this.$config.googleAnalytics.googleAnalyticsFourActive || this.$isSpider,
          body: true,
        },
        {
          type: 'text/javascript',
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${this.$config.googleTagManager.googleTagManagerAccount}');`,
          skip: !this.$config.googleTagManager.googleTagManagerActive || this.$isSpider,
          body: true,
        },
        {
          src: `https://www.googletagmanager.com/gtag/js?id=${this.$config.googleAnalytics.googleAnalyticsFourAccount}`,
          skip: !this.$config.googleAnalytics.googleAnalyticsFourActive || this.$isSpider,
          async: true,
          body: true,
        },
        {
          hid: 'fb-pixel',
          innerHTML: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${this.$config.metaPixel.pixelId});
            fbq('track', 'PageView');
          `,
          type: 'text/javascript',
          charset: 'utf-8',
          skip: !this.$config.metaPixel?.pixelId || this.$isSpider,
        },
      ],
      noscript: [
        {
          type: 'text/javascript',
          // eslint-disable-next-line max-len,vue/max-len
          innerHTML: ` <iframe src="https://www.googletagmanager.com/ns.html?id=${this.$config.googleTagManager.googleTagManagerAccount};?>"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          body: true,
          skip: !this.$config.googleTagManager.googleTagManagerActive || this.$isSpider,
        },
        {
          innerHTML: `
            <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=${this.$config.metaPixel?.pixelId}&ev=PageView&noscript=1"/>
          `,
          body: true,
        },
      ],
      __dangerouslyDisableSanitizers: ['script', 'noscript'],

      meta: [
        {
          name: 'robots',
          content: this.headData?.robots || 'index, follow',
        },
        {
          hid: 'description',
          name: 'description',
          content: this.headData?.description ? this.headData.description
            : '1Stop Bedrooms is your one stop shop for bedroom furniture. Shop the largest collection of bedroom sets. 5 star rated. Free delivery. Financing available.',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.ogData?.title || '1StopBedrooms',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.ogData?.url || 'https://www.1stopbedrooms.com/',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.ogData?.description ? this.headData.description
            : '1Stop Bedrooms is your one stop shop for bedroom furniture. Shop the largest collection of bedroom sets. 5 star rated. Free delivery. Financing available.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogData?.image || 'https://www.1stopbedrooms.com/media/1stopbedrooms-logo.jpg',
        },
        {
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content: this.ogData?.image_alt || '1StopBedrooms',
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.ogData?.url || '',
        },
      ],
    };
  },
  async fetch() {
    await this.fetchData();
  },
  computed: {
    headData() {
      return this.$store.state.aboutUs?.aboutMetaData?.addons?.head;
    },
  },
  watch: {
    overlay(val) {
      /*
       * The "hasOverlayWithDelay" property changes to false with a delay.
       * This is to remove the "hasOverlay" class with a delay.
       * This prevents clicking on site elements under the <v-overlay>.
       */

      if (val) {
        this.hasOverlayWithDelay = val;
      } else {
        new Promise((res) => {
          setTimeout(res, 500);
        }).then(() => {
          this.hasOverlayWithDelay = val;
        });
      }
    },
    '$route.path': function watchRoute() {
      this.$nextTick(() => {
        this.getMetadata();
      });
    },
  },
  created() {
    this.$nuxt.$on('change-global-loading', (status) => {
      this.loadingPopup = status;
    });
  },
  methods: {
    async getMetadata() {
      this.redirect = null;
      const parameters = Object.entries(this.$route.query)
        .map(([name, value]) => ({ name, value: (value || '').split('$,$') }));
      const result = await this.$graphql.default.request(GET_META_DATA, {
        slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
        parameters: parameters.length ? parameters : null,
      });
      const redirect = result.pageMetadata?.addons?.redirect?.url;
      if (redirect) {
        this.redirect = redirect[0] === '/' ? redirect : `/${redirect}`;
        window.location.href = this.redirect;
        return;
      }
      this.$store.commit('aboutUs/setAboutMetaData', result.pageMetadata || {});
    },
    async fetchData() {
      const f1 = async () => {
        const parameters = Object.entries(this.$route.query)
          .map(([name, value]) => ({ name, value: (value || '').split('$,$') }));
        const result = await this.$graphql.default.request(GET_META_DATA, {
          slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
          parameters: parameters.length ? parameters : null,
        });
        const redirect = result.pageMetadata?.addons?.redirect?.url;
        if (redirect) {
          this.redirect = redirect[0] === '/' ? redirect : `/${redirect}`;
          window.location.href = this.redirect;
          return;
        }
        this.$store.commit('aboutUs/setAboutMetaData', result.pageMetadata || {});
      };

      const f2 = async () => {
        this.redirect = null;
        try {
          const defaultParams = {
            slug: this.$route.path[0] === '/' ? this.$route.path.slice(1) : this.$route.path,
          };
          const result = await this.$graphql.default.request(GET_ABOUT_US_HEADER, defaultParams) || {};
          this.headerData = result?.cmsPage?.header;
          this.$store.commit('aboutUs/setAboutheaderData', this.headerData || {});
          this.footerData = result?.cmsPage?.footer;
          this.$store.commit('aboutUs/setAboutFooterData', this.footerData || {});
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      try {
        await Promise.allSettled([
          f1(), f2(),
        ]);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
